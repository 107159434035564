export function handleQueryResolve(results, err) {
    if (results) {
        return results
    }
    if (err) {
        console.error(err)
    }

    // return empty array if no results
    return []
}

export function buildMapToField(objToMap) {
    const mapFields = getBasicMap() // Basic field mappers
    if (!objToMap) {
        throw new Error('${objToMap} is undefined')
    }

    return Object.keys(objToMap).reduce((cur, queryField) => {
        try {
            const key = mapFields[queryField]
            const val = objToMap[queryField]
            if (!key) {
                return cur

                // FIXME  We need to map values into one single interface
                //        Under getBasicMap() for different integrations
                // return {
                //   ...cur,
                //   [queryField]: val
                // };
            }

            if (typeof key === 'function') {
                return {
                    ...cur,
                    ...key(val),
                }
            }

            // if its straight up
            return {
                ...cur,
                [key]: val,
            }
        } catch (err) {
            console.error(err, ` >> queryField: ${queryField}`, { mapFields }, { objToMap })
        }
    }, {})
}

export function getBasicMap() {
    /*
            Nexxsys procedure calls returns specific fields that can't be mapped
            directly to match the rest of our mappers. therefore we will see some
            redundant aliased data around some properties.

            the structure is as follows:
            {
                [results of SQL column name]: alias that gets mapped to
            }
        */
    return {
        FirstName: 'FIRST NAME',
        LastName: 'LAST NAME',
        FamilyDoc: familyDocName => {
            // when they don't exist
            if (!familyDocName) {
                return {
                    'PRIMARY LAST NAME': '',
                    'PRIMARY FIRST NAME': '',
                }
            }

            const brokenName = familyDocName.split(',')

            // FIXME should extract from base
            return {
                'PRIMARY LAST NAME': brokenName[0].trim(),
                'PRIMARY FIRST NAME': brokenName[1].trim(),
            }
        },
        OHIP: 'HEALTH CARD NUMBER',
        Address: mapFunctions.patientAddress,
        City: 'CITY\/TOWN',
        Province: mapFunctions.defaultProvince,
        Postal: 'POSTAL CODE',
        EMail: 'EMAIL ADDRESS',
        HomePhone: 'TELEPHONE NUMBER',
        MobilePhone: 'CELLPHONE NUMBER',
        Allergies: 'LIST OF ALLERGIES',
        DOB: 'DATE OF BIRTH',
        Gender: 'GENDER',
        Medication: 'Medication',
        PatientID: 'PatientID',
        DoctorID: 'DoctorID',
        DIN: 'DIN',
        Days: 'DAYS',
        brandname: 'BrandName',
        genericname: 'GenericName',
        GenericName: 'GenericName',
        BrandName: 'BrandName',
        tradename: 'BrandName',
        RxNumber: 'RxNumber',
        RxID: 'RxID',
        RxDate: 'RxDate',
        FillDate: 'FillDate',
        RxStrength: 'RxStrength',
        RxQtyDispense: 'RxQtyDispense',
        SIGFull: 'SIG',
        instructionstext: 'SIG',
        instructiontext: 'SIGFull',
        RxDays: 'RxDays',
        RxInterval: 'RxInterval',
        drugcolour: 'description',
        InsuranceProvider: 'InsuranceProvider',
        Rank: 'Rank',

        PharmacyAddress: mapFunctions.pharmacyAddress,
        PharmacyCity: 'PHARMACY CITY',
        PharmacyFax: 'PHARMACY FAX NUMBER',
        PharmacyName: 'PHARMACY NAME',
        PharmacyPhone: 'PHARMACY TELEPHONE NUMBER',
        PharmacyPostal: 'PHARMACY POSTAL CODE',
        PharmacyProvince: 'PHARMACY PROVINCE',

        DoctorFirstName: 'PRIMARY FIRST NAME',
        DoctorLastName: 'PRIMARY LAST NAME',
        DoctorEmail: 'PRIMARY EMAIL ADDRESS',
        DoctorPhone: 'PRIMARY PHONE #',
        DoctorFax: 'PRIMARY FAX #',
        DoctorAddress: mapFunctions.primaryAddress,
        DoctorCity: 'PRIMARY CITY',
        DoctorProvince: 'PRIMARY POSTAL CODE',
        DoctorPostal: 'PRIMARY PROVINCE',
        CPSO: 'CPSO',
        medName: 'medName',
        date: 'date',
        qty: 'qty',
        type: 'type',

        // 'DaysSupply':'DAYS'
        PlanCode: 'PlanCode',
        CarrierID: 'CarrierID',
        PlanID: 'PlanID',
        GroupID: 'GroupID',
        PlanOrder: 'PlanOrder',
        Relationship: 'Relationship',
        PatientCode: 'PatientCode',

        // '': 'PRIMARY EMAIL ADDRESS',
        // 'noon': 'Noon',
        // 'sup': 'Supp',
        // 'bed': 'Bed',
        // 'bkf': 'Brk',
        noon: mapFunctions.convertNullTo('', 'Noon'),
        sup: mapFunctions.convertNullTo('', 'Supp'),
        bed: mapFunctions.convertNullTo('', 'Bed'),
        bkf: mapFunctions.convertNullTo('', 'Brk'),
        instructionstext: 'SIG',

        // nexxsys procedure calls returns this field
        'trim(drug.strength)': 'Strength',

        // compounds
        MixGenericName: 'MixGenericName',
        MixQuantity: 'MixQuantity',
        UnitCode: 'UnitCode',
        Rank: 'Rank',
        description: 'description',
        fillquantity: 'fillquantity',
        mixingminutes: 'mixingminutes',
        MixtureId: 'MixtureId',
        drugid: 'drugid',
    }
}

// functional mappings that extends
const mapFunctions = {
    defaultProvince: province => ({
        PROVINCE: province || 'ONTARIO',
    }),
    patientAddress: address => {
        const breakdown = parseAddress(address)

        return {
            'UNIT NUMBER': breakdown.unitNumber,
            'STREET NUMBER': breakdown.streetNumber,
            'STREET NAME': breakdown.streetName,
        }
    },
    pharmacyAddress: address => {
        const breakdown = parseAddress(address)

        return {
            'PHARMACY UNIT NUMBER': breakdown.unitNumber,
            'PHARMACY STREET NUMBER': breakdown.streetNumber,
            'PHARMACY STREET NAME': breakdown.streetName,
        }
    },
    primaryAddress: address => {
        const breakdown = parseAddress(address)

        return {
            'PRIMARY UNIT NUMBER': breakdown.unitNumber,
            'PRIMARY STREET NUMBER': breakdown.streetNumber,
            'PRIMARY STREET NAME': breakdown.streetName,
        }
    },
    convertNullTo: (convertedValue, key) => value =>
        // computed property ? is a shortcut if statment (CLOSURE: returning a customized function based on another funciton)
        ({
            [key]: value == null || (value && typeof value === 'string' && value.toLowerCase() == 'null') ? convertedValue : value,
        }),
}

function parseAddress(addressStr) {
    let unitNumber = ''
    let streetNumber = ''
    let streetName = ''
    const streetBreakdown = addressStr.match(/[A-za-z]+[\s|A-za-z]+/g)
    if (streetBreakdown && streetBreakdown.length) {
        streetName = streetBreakdown[0].trim()
        streetNumber = addressStr.replace(streetName, '').trim()
        if (streetNumber.indexOf('-') != -1) {
            const numbersBreakdown = streetNumber.split('-')
            unitNumber = numbersBreakdown[0].trim()
            streetNumber = numbersBreakdown[1].trim()
        }
    }

    return {
        unitNumber,
        streetNumber,
        streetName,
    }
}