import {
  handleQueryResolve
} from '../utils';

export default function(){
  return this.query(`
  SELECT 
  "DBA"."Drug"."ManufacturerCode" AS Mfr
  FROM "DBA"."Prescription" JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
  WHERE "DBA"."Drug"."ManufacturerCode" <> '' OR "DBA"."Drug"."ManufacturerCode" IS NOT NULL
  GROUP BY 
  "DBA"."Drug"."ManufacturerCode"
  ORDER BY "DBA"."Drug"."ManufacturerCode" ASC
  `).then(handleQueryResolve)
  // .then((records) => {
  //   console.log(records);
  //   return records.map(row => buildMapToField(row));
  // });
}